import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BlogCard from '../components/BlogCard';
import TrendingComponent from '../components/Trending';
export default function Blog() {
  const blogPosts = [
    { title: "The Future of AdTech", excerpt: "Exploring upcoming trends in advertising technology..." },
    { title: "MarTech Innovations", excerpt: "Breaking down the latest marketing technology advancements..." },
    { title: "Data Privacy in Advertising", excerpt: "Navigating the complex landscape of data regulations..." },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className="lg:col-span-8">
              <h1 className="text-4xl font-bold mb-6">Blog</h1>
              <div className="grid grid-cols-1 gap-6">
                {blogPosts.map((post, index) => (
                  <BlogCard key={index} title={post.title} excerpt={post.excerpt}/>
                ))}
              </div>
            </div>
            <div className="lg:col-span-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <h2 className="text-xl font-semibold mb-4">Trending</h2>
                <TrendingComponent></TrendingComponent>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}