/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

function VideoCard({ title, thumbnail }) {
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden hover:bg-purple-200">
      <img src={thumbnail} alt={title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <a href="#" className="text-purple-500 hover:font-bold">Watch now</a>
      </div>
    </div>
  );
}

export default VideoCard;
