import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TrendingComponent from '../components/Trending';

export default function Home() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className="lg:col-span-8">
              <div className='flex flex-col items-center justify-center py-5'>
                <img src="/logo.png" alt="MADSense Logo" className="h-32" />  
              </div>           
              <Link 
                to="/videos" 
                className="inline-block px-6 py-3 text-xl bg-purple-200 text-purple-800 rounded-lg hover:bg-purple-300 transition duration-300 ease-in-out mb-4 flex flex-col items-center w-80 m-auto"
              >
                Explore the Catalog
              </Link>
              <section className="mt-8">
                <h2 className="text-2xl font-semibold mb-4">About MADSense</h2>
                <p className="mb-4">
                  MADSense is a Marketing and Advertising Technology (MAD Tech) analyst platform
                  that utilizes the power of inside knowledge to understand the intricacies of
                  new and existing technologies, partnerships, functionalities, and regulations.
                </p>
              </section>
            </div>
            <div className="lg:col-span-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <h2 className="text-xl font-semibold mb-4">Trending</h2>
                <TrendingComponent></TrendingComponent>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}