/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

function BlogCard({ title, excerpt }) {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 hover:bg-purple-200">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{excerpt}</p>
      <a href="#" className="text-purple-500 hover:font-bold mt-4 inline-block">Read more</a>
    </div>
  );
}

export default BlogCard;
