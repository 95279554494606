import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import VideoCard from '../components/VideoCard';
import EmailSubscription from '../components/EmailSubscription';
import TrendingComponent from '../components/Trending';
export default function Videos() {
  const videos = [
    { title: "Understanding Programmatic Advertising", thumbnail: "/images/video1.png" },
    { title: "The Role of AI in Marketing", thumbnail: "/images/video2.png" },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className="lg:col-span-8">
              <h1 className="text-4xl font-bold mb-6">Videos</h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {videos.map((video, index) => (
                  <VideoCard key={index} title={video.title} thumbnail={video.thumbnail} />
                ))}
              </div>
            </div>
            <div className="lg:col-span-4">
              <div className="bg-gray-100 p-4 rounded-lg mb-6">
                <h2 className="text-xl font-semibold mb-4">Trending</h2>
                <TrendingComponent></TrendingComponent>
              </div>
              <EmailSubscription />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}