import React, { useState, useEffect } from 'react';

const TrendingComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Simulate loading data
    setTimeout(() => {
      setItems(['Google Ad Manager', 'Kevel', 'OpenX', 'Broadstreet']);
      setIsLoading(false);
    }, 2000); // 2 seconds delay to simulate network request
  }, []);

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <ul className="list-none pl-5">
        {isLoading
          ? Array(4).fill().map((_, index) => (
              <li key={index} className="mb-2 p-2 rounded hover:bg-purple-300">
                <div className="h-4 bg-gray-200 rounded animate-pulse w-3/4"></div>
              </li>
            ))
          : items.map((item, index) => (
              <li key={index} className="mb-2 p-2 rounded hover:bg-purple-300">{item}</li>
            ))
        }
      </ul>
    </div>
  );
};

export default TrendingComponent;