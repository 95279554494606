import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const closeMenu = (e) => {
      if (isMenuOpen && !e.target.closest(".menu-container")) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", closeMenu);
    return () => document.removeEventListener("click", closeMenu);
  }, [isMenuOpen]);

  return (
    <header className="bg-white border-b border-gray-800 relative z-50">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center menu-container w-1/3">
            <button
              onClick={toggleMenu}
              className="text-black hover:text-black-200 focus:outline-none focus:text-black"
              aria-label="toggle menu"
            >
              <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
            {isMenuOpen && (
              <nav className="absolute left-0 top-full w-64 bg-white border border-gray-200 shadow-lg">
                <div className="block px-4 py-2">
                  <img src="/logo.png" alt="MADSense Logo" className="h-16" />
                </div>
                <ul className="py-1">
                  <li>
                    <Link
                      to="/"
                      className="block px-4 py-2 text-gray-700 hover:bg-purple-300"
                      onClick={toggleMenu}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blog"
                      className="block px-4 py-2 text-gray-700 hover:bg-purple-300"
                      onClick={toggleMenu}
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/videos"
                      className="block px-4 py-2 text-gray-700 hover:bg-purple-300"
                      onClick={toggleMenu}
                    >
                      Videos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="block px-4 py-2 text-gray-700 hover:bg-purple-300"
                      onClick={toggleMenu}
                    >
                      About
                    </Link>
                  </li>
                </ul>
              </nav>
            )}
          </div>
          <div className="flex justify-center items-center w-1/3">
            <h1 className="font-bold text-2xl font-['Montserrat',sans-serif]">MADSENSE</h1>
          </div>
          <div className="flex flex-row justify-end items-center w-1/3">
            <button className="flex" aria-label="Search">
              <svg className="inline-block ml-2 w-4 h-4 fill-current">
                <use href="./images/icons/spritemap.svg#sprite-search-solid"></use>
              </svg>
            </button>
            <span
              x-show="!searchOpen"
              className="hidden ml-2 text-sm text-base font-semibold lg:block"
            >
              Search
            </span>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;